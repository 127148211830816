const PENDING_PAYMENT_ORDER_STATUS = 'Pending payment'
const CANCELLED_ORDER_STATUS = 'Cancelled'
const PROCESSING_ORDER_STATUS = 'Processing'
const PROCESSED_ORDER_STATUS = 'Processed'
const CONFIRMED_ORDER_STATUS = 'Confirmed'
const INVOICED_ORDER_STATUS = 'Invoiced'
const PACKING_ORDER_STATUS = 'Packing'
const READY_FOR_COLLECTION_ORDER_STATUS = 'Ready for collection'
const OUT_FOR_DELIVERY_ORDER_STATUS = 'Out for delivery'
const DELIVERED_ORDER_STATUS = 'Delivered'

const OPEN_STATUS = 'Open'
const CLOSED_STATUS = 'Closed'

module.exports = {
  PENDING_PAYMENT_ORDER_STATUS,
  CANCELLED_ORDER_STATUS,
  PROCESSING_ORDER_STATUS,
  PROCESSED_ORDER_STATUS,
  CONFIRMED_ORDER_STATUS,
  INVOICED_ORDER_STATUS,
  PACKING_ORDER_STATUS,
  READY_FOR_COLLECTION_ORDER_STATUS,
  OUT_FOR_DELIVERY_ORDER_STATUS,
  DELIVERED_ORDER_STATUS,
  OPEN_STATUS,
  CLOSED_STATUS,
}
