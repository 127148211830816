import { useEffect, useState } from 'react'
import { useQuery, useMutation, useInfiniteQuery } from 'react-query'
import API from '.'
import { serializeQuery } from './utils'
import { sortNumbers, sortSites, sortStrings, sortYears } from '../utils/sort'
import { HTA_DEALER_ROLE, SALES_ROLE } from '../../../server/constants/roles'
import { useAuth } from '../hooks/context/AuthContext'
import { MALAYSIA, queryOrigins } from '../../../shared/constants/countries-map'
import { showStockOnlyForSales } from '../../../shared/config/regional-configs'
import { SITES_MAP } from '../../../shared/constants/sites-map'
import { usePostTrack } from './analytics'
import _ from 'lodash'

const ENDPOINT = '/api/lubricants'
const QUERY_KEY = 'lubricants'

export const useGetLubricants = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', query],
    async () => (await API.get(serializeQuery(ENDPOINT, query))).data,
    config
  )

export const useGetLubricantBrands = (query = {}, config = {}) =>
  useQuery(
    ['lubricant_brands', 'brand-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/brands`, query))).data,
    config
  )

export const useGetLubricantsFrontEnd = (
  page = 1,
  limit = 20,
  sort = 'brandAsc',
  filters = {},
  query = {},
  config = {}
) => {
  const { user, country } = useAuth()
  const getInStockOnly = () =>
    Number(
      filters.inStockOnly?.[0] ?? (showStockOnlyForSales[country] || user?.role !== SALES_ROLE)
    )

  limit = user.role !== SALES_ROLE ? 20 : 100
  const [inStockOnly, setInStockOnly] = useState(getInStockOnly())

  const lubricants = useGetLubricants(
    { ...query, ...filters, inStockOnly, page, limit, sort },
    config
  )

  const [searchResults, setSearchResults] = useState({})
  const [filtered, setFiltered] = useState({ ...lubricants })
  const [paginated, setPaginated] = useState({ ...lubricants })

  const { mutateAsync: track } = usePostTrack()

  useEffect(() => {
    if (getInStockOnly() !== inStockOnly) {
      setInStockOnly(getInStockOnly())
    }
  }, [filters])

  const filterResults = (data = [], filters = {}) =>
    !Array.isArray(data)
      ? []
      : data?.map((item) => ({
          id: item.brand + item.name + item.site,
          ...item,
        }))

  useEffect(() => {
    setFiltered(() => {
      const newFiltered = { ...lubricants }
      if (newFiltered?.data) {
        let docs = filterResults(newFiltered?.data?.data || [], filters)

        newFiltered.data = {
          docs,
          pagination: newFiltered?.data?.pagination,
          totalDocs: newFiltered?.data?.pagination.length,
          inStockOnly: getInStockOnly(),
        }
      }

      setSearchResults(() => {
        const newFilters = _.pickBy(filters, _.identity)
        const resultFilters = {}
        for (const [key, value] of Object.entries(newFilters)) {
          if (!value || value?.length === 0) {
            continue
          }
          let arr = value || []
          if (!Array.isArray(arr)) {
            arr = [arr]
          }
          arr.sort()
          resultFilters[key] = arr
        }
        return { filters: resultFilters, count: newFiltered?.data?.totalDocs }
      })
      return newFiltered
    })
  }, [filters, sort, lubricants.status, lubricants.isFetching])

  useEffect(() => {
    setPaginated(() => {
      const newPaginated = { ...filtered }
      if (filtered.isSuccess) {
        newPaginated.data = {
          ...filtered.data,
          docs: filtered.data?.docs || [],
          totalPages: filtered.data?.pagination?.total,
          page,
          limit,
        }
      }
      return newPaginated
    })
  }, [filtered, page, limit])

  useEffect(() => {
    if (filtered?.isSuccess && Object.keys(searchResults?.filters || {})?.length > 0) {
      track({
        event: 'Lubricant Search Results',
        props: searchResults,
      })
    }
  }, [JSON.stringify(searchResults), filtered?.isSuccess])

  return [paginated, filtered]
}

export const useGetLubricantsInfinite = (query = {}, config = {}) =>
  useInfiniteQuery(
    QUERY_KEY,
    async ({ pageParam = 1 }) =>
      (await API.get(serializeQuery(ENDPOINT, { page: pageParam, ...query }))).data,
    {
      getPreviousPageParam: (firstPage, pages) => firstPage.prevPage,
      getNextPageParam: (lastPage, pages) => lastPage.nextPage,
      /*select: (data) => {
        console.log(data.pages, data.pageParams)
        return {
          pages: data.pages.slice(1),
          pageParams: data.pageParams.slice(1),
        }
      },*/
      ...config,
    }
  )

export const useGetLubricantQty = (lubricant, config = {}) => {
  if (!lubricant) return

  const { itemRef = '', brand, name, site, subSites } = lubricant

  const params = {
    itemRef,
    brand,
    name,
    site: subSites?.join(',') || site,
  }

  const query = new URLSearchParams(params).toString()

  const path = `${ENDPOINT}/qty?${query}`

  return useQuery([QUERY_KEY, 'qty', query], async () => (await API.get(path)).data, config)
}
