import React from 'react'

import ProcessedIcon from '@material-ui/icons/Info'
import ProcessingIcon from '@material-ui/icons/PlayCircleFilled'
import ConfirmedIcon from '@material-ui/icons/CheckCircle'
import CancelledIcon from '@material-ui/icons/Cancel'
import InvoicedIcon from '@material-ui/icons/Receipt'
import DeliveringIcon from '@material-ui/icons/LocalShipping'

import * as promotionTypes from '../../../../shared/constants/promotion-types'
import * as orderStatus from '../../../../shared/constants/order-status'
import { blue, green, orange } from '@material-ui/core/colors'
import { uniq } from 'lodash'
import { htgCompanyOptions } from '../../../../shared/config/regional-configs'
import { BRAKE } from 'shared/constants/companies'

export const status = {
  [orderStatus.PENDING_PAYMENT_ORDER_STATUS]: {
    color: { style: { color: orange[700] } },
    icon: <ProcessingIcon style={{ fill: orange[700] }} />,
  },
  [orderStatus.CONFIRMED_ORDER_STATUS]: {
    color: { style: { color: green[500] } },
    icon: <ConfirmedIcon style={{ fill: green[500] }} />,
  },
  [orderStatus.PROCESSING_ORDER_STATUS]: {
    color: { color: 'primary' },
    icon: <ProcessingIcon color='primary' />,
  },
  [orderStatus.PROCESSED_ORDER_STATUS]: {
    color: { style: { color: blue[500] } },
    icon: <ProcessedIcon style={{ fill: blue[500] }} />,
  },
  [orderStatus.CANCELLED_ORDER_STATUS]: {
    color: { color: 'error' },
    icon: <CancelledIcon color='error' />,
  },
  [orderStatus.PACKING_ORDER_STATUS]: {
    color: { style: { color: green[500] } },
    icon: <ProcessingIcon style={{ fill: green[500] }} />,
  },
  [orderStatus.READY_FOR_COLLECTION_ORDER_STATUS]: {
    color: { style: { color: green[500] } },
    icon: <ProcessingIcon style={{ fill: green[500] }} />,
  },
  [orderStatus.INVOICED_ORDER_STATUS]: {
    color: { style: { color: green[500] } },
    icon: <InvoicedIcon style={{ fill: green[500] }} />,
  },
  [orderStatus.OUT_FOR_DELIVERY_ORDER_STATUS]: {
    color: { style: { color: green[500] } },
    icon: <DeliveringIcon style={{ fill: green[500] }} />,
  },
  [orderStatus.DELIVERED_ORDER_STATUS]: {
    color: { style: { color: green[500] } },
    icon: <ConfirmedIcon style={{ fill: green[500] }} />,
  },
}

export const getTotalQty = (orderItems) =>
  orderItems?.length ? orderItems.reduce((total, item) => (total += item.quantity), 0) : 0

export const getSubtotal = (orderItems) =>
  orderItems?.length
    ? orderItems.reduce((total, item) => (total += item.unitPrice * item.quantity), 0)
    : 0

export const getTotalDiscount = (orderItems, promoCodeObj) => {
  const orderItemsDiscount = orderItems?.length
    ? orderItems.reduce((total, item) => (total += (item.unitDiscount || 0) * item.quantity), 0)
    : 0

  const promoCodeDiscountAmount =
    promoCodeObj?.type === promotionTypes.AMOUNT ? promoCodeObj?.discountAmount : 0

  const subtotal = getSubtotal(orderItems)
  const promoCodeDiscountPercentageAmount =
    promoCodeObj?.type === promotionTypes.PERCENTAGE
      ? subtotal * (promoCodeObj?.discountPercentage ?? 0) >= promoCodeObj?.discountCap ?? 0
        ? promoCodeObj?.discountCap
        : subtotal * (promoCodeObj?.discountPercentage ?? 0)
      : 0

  return orderItemsDiscount + promoCodeDiscountAmount + promoCodeDiscountPercentageAmount
}

export const getTotalTax = (orderItems) =>
  orderItems?.length
    ? orderItems.reduce((total, item) => (total += (item.unitTax || 0) * item.quantity), 0)
    : 0

export const getTotal = (orderItems, promoCodeObj) => {
  const orderItemsTotal = orderItems?.length
    ? orderItems.reduce(
        (total, item) =>
          (total +=
            item.subTotal ||
            (item.unitPrice - (item.unitDiscount || 0) + (item.unitTax || 0)) * item.quantity),
        0
      )
    : 0

  const promoCodeDiscountAmount =
    promoCodeObj?.type === promotionTypes.AMOUNT ? promoCodeObj?.discountAmount : 0

  const subtotal = getSubtotal(orderItems)
  const promoCodeDiscountPercentageAmount =
    promoCodeObj?.type === promotionTypes.PERCENTAGE
      ? subtotal * (promoCodeObj?.discountPercentage ?? 0) >= promoCodeObj?.discountCap
        ? promoCodeObj?.discountCap ?? 0
        : subtotal * (promoCodeObj?.discountPercentage ?? 0)
      : 0

  return orderItemsTotal - promoCodeDiscountPercentageAmount - promoCodeDiscountAmount
}

export const getPromos = (orderItems) =>
  orderItems
    ?.map((item) => item.promotions || item.appliedPromotions)
    ?.flat()
    ?.reduce(
      (acc, promo) =>
        !!acc.find((uniquePromo) => uniquePromo?.id === promo?.id) ? acc : [...acc, promo],
      []
    ) || []

export const getFreeGifts = (orderItems, promoCodeObj) => {
  const orderItemFreeGifts = getPromos(orderItems)
    .filter((item) => item?.type === promotionTypes.GIFT)
    .map((item) => item?.freeGift)
    .filter((v, i, a) => a.indexOf(v) === i)

  const promoCodeFreeGifts =
    promoCodeObj?.type === promotionTypes.GIFT && promoCodeObj.freeGift
      ? [promoCodeObj.freeGift]
      : []

  return [...orderItemFreeGifts, ...promoCodeFreeGifts]
}

export const checkCreditLimit = (country, companyAccounts = [], orderItems = []) => {
  const exceededCompanyAccounts = []
  for (const companyAccount of companyAccounts) {
    if (companyAccount?.htgCompany !== BRAKE) {
      if (companyAccount?.amountDue > 0) {
        exceededCompanyAccounts.push(companyAccount.htgCompany)
        continue
      }
      const totalByProductType = getTotal(
        orderItems.filter(({ productType }) =>
          htgCompanyOptions[country]
            .find((htgCompany) => htgCompany.value === companyAccount.htgCompany)
            ?.productTypes?.includes(productType)
        )
      )
      const balanceAfterPurchase = companyAccount?.availableCredit - totalByProductType
      if (balanceAfterPurchase < 0) {
        exceededCompanyAccounts.push(companyAccount.htgCompany)
      }
    }
  }
  return uniq(exceededCompanyAccounts)
}
