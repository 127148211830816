import React, { useContext } from 'react'

import { useAuth } from '../../../shared/hooks/context/AuthContext'
import { useGetCart } from '../../../shared/api/cart'

import SharedHeader from '../../../shared/components/ui/Header'

import Badge from '@material-ui/core/Badge'

import SearchIcon from '@material-ui/icons/Search'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import ReceiptIcon from '@material-ui/icons/Receipt'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import LocalOfferRounded from '@material-ui/icons/LocalOfferRounded'

import { getTotalQty } from '../../../client/utils/helpers/orderHelpers'
import { enableKeepStockOrders, enablePromotions } from '../../../../shared/config/regional-configs'

import {
  CS_ROLE,
  DEALER_ROLE,
  HTA_DEALER_ROLE,
  HTS_DEALER_ROLE,
  SALES_ROLE,
} from '../../../../server/constants/roles'
import { useKeepStock } from '../../../shared/hooks/context/KeepStockContext'
import CsContext from '../../../shared/hooks/context/CsContext'

const disallowedRolesPromotion = [HTA_DEALER_ROLE, HTS_DEALER_ROLE, CS_ROLE]

const Header = ({ routes }) => {
  const { user, country, hasCartAccess } = useAuth()
  const enableOrders =
    user?.accessConfig?.orders && (user?.role !== HTA_DEALER_ROLE || user?.htaConfig?.allowOrder)

  const enablePromotionsLink =
    user?.accessConfig?.promotions &&
    enablePromotions[country] &&
    !disallowedRolesPromotion.includes(user?.role)

  const enableKeepStock =
    enableKeepStockOrders[country] && [DEALER_ROLE, SALES_ROLE].includes(user?.role)

  const { count } = enableKeepStock ? useKeepStock() : {}
  const cart = useGetCart({ enabled: hasCartAccess })

  const isCs = user?.role === CS_ROLE
  const { orderData, ksOrderData, getProcessingQty } = isCs ? useContext(CsContext) : {}

  const menuItems = {
    primary: [
      user &&
        !isCs && {
          label: 'Search tyres',
          to: '/search',
          icon: <SearchIcon />,
        },
      hasCartAccess && {
        label: 'My cart',
        to: '/cart',
        icon: (
          <Badge overlap='rectangular' badgeContent={getTotalQty(cart.data?.orderItems)}>
            <ShoppingCartOutlinedIcon />
          </Badge>
        ),
      },
    ].filter(Boolean),
    secondary: [
      enableKeepStock && {
        label: 'Keep Stock List',
        to: '/keep-stock',
        icon: (
          <Badge overlap='rectangular' badgeContent={count}>
            <LocalShippingIcon />
          </Badge>
        ),
      },
      enableOrders && { label: 'Orders', to: '/orders', icon: <ReceiptIcon /> },
      enablePromotionsLink && {
        label: 'Promotions',
        to: '/client-promotion',
        icon: <LocalOfferRounded />,
      },
      user?.accessConfig?.promotionPanel && {
        label: 'Promotions Panel',
        to: '/cs/promotion',
      },
      isCs && {
        label: (
          <Badge overlap='rectangular' badgeContent={getProcessingQty(orderData)}>
            Cs Panel
          </Badge>
        ),
        to: '/cs',
      },
    ].filter(Boolean),
  }

  return <SharedHeader menuItems={menuItems} routes={routes} />
}

export default Header
