import { useQuery, useMutation, useQueryClient } from 'react-query'
import API from '.'
import { useAuth } from '../hooks/context/AuthContext'
import { serializeQuery } from './utils'

const ENDPOINT = '/api/users'
const QUERY_KEY = 'users'

export const useMe = (config = {}) =>
  useQuery(
    [QUERY_KEY, 'detail', 'me'],
    async () =>
      (
        await API.get(`${ENDPOINT}/me`).catch((err) => {
          if (err.response?.status === 401) return { data: null }
          throw err
        })
      ).data,
    config
  )

export const useGetProfile = (authId, config = {}) =>
  useQuery(
    [QUERY_KEY, 'profile', authId],
    async () =>
      !authId
        ? { email: '', phone: '', firstName: '', lastName: '' }
        : (
            await API.get(`${ENDPOINT}/profile/${authId}`).catch((err) => {
              if (err.response?.status === 401) return { data: null }
              throw err
            })
          ).data,
    config
  )

export const useGetUsers = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', query],
    async () => (await API.get(serializeQuery(ENDPOINT, query))).data,
    config
  )

export const useGetDealers = (config = { refetchOnWindowFocus: false }) =>
  useQuery([QUERY_KEY, 'dealers'], async () => (await API.get(`${ENDPOINT}/dealers`)).data, config)

export const useGetSalesDealers = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'sales-dealers', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/sales/dealers`, query))).data,
    config
  )

export const useGetBillingAccess = (config = {}) => {
  const { user } = useAuth()
  const appName = 'billing'
  const queryClient = useQueryClient()
  return useQuery(
    ['access', user?.id || '', appName],
    async () => {
      if (!user) {
        return null
      }
      const data = queryClient.getQueryData(['access', user.id, appName])
      if (data != null) {
        return data
      }
      return (await API.get(`${ENDPOINT}/access/${appName}`)).data?.[appName]
    },
    config
  )
}

export const useCreateUser = (config = {}) =>
  useMutation((newData) => API.post(ENDPOINT, newData), config)

export const useEditUser = (config = {}) =>
  useMutation((updatedData) => API.patch(ENDPOINT, updatedData), config)

export const useActivateUser = (config = {}) =>
  useMutation((data) => API.post(`${ENDPOINT}/activate-user`, data), config)

export const useDeactivateUser = (config = {}) =>
  useMutation((data) => API.post(`${ENDPOINT}/deactivate-user`, data), config)

export const useRequestSignup = (config = {}) =>
  useMutation((data) => API.post(`/api/request-signup`, data), config)

export const useExportUsers = (config = {}) =>
  useMutation(() => API.get(`${ENDPOINT}/export-users`, { responseType: 'blob' }), config)

export const useGetUserExpiry = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/expiry`, query))).data,
    config
  )

export const useExportUsersExpiring = (config = {}) =>
  useMutation(() => API.get(`${ENDPOINT}/export-users-expiry`, { responseType: 'blob' }), config)
